<template>
  <div class="wedo_content">
    <div class="left">
      <img src="../assets/image/001.png" />
      <div class="left_tu">
        <img src="../assets/image/002.png" />
      </div>
    </div>
    <div class="right">
      <img src="../assets/image/003.png" />
    </div>
  </div>
</template>

<style lang="less" scoped>
.wedo_content {
  width: 1200px;
  height: 502px;
  color: #000;
  background-color: rgb(255, 255, 255);
  margin-top: 100px;
  margin-left: 60px;

  .left {
    width: 600px;
    height: 502px;
    background-color: rgb(255, 255, 255);
    float: left;
    .left_tu {
      margin-top: 40px;
    }
  }
  .right {
    width: 600px;
    height: 502px;
    background-color: rgb(255, 255, 255);
    float: right;
    img {
      margin-left: 80px;
    }
  }
}
</style>
